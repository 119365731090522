import React, { useRef } from "react";
import Image from '~/components/elements/Image';
import yellowShape from '~/assets/images/yellow-shape.svg';
import { motion } from 'framer-motion';
import { Button } from '~/components/elements/buttons/Button';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

export const CaseStudyNewHead = (props) => {
  const { heading, subheading, description, previewImage, quote, logo, name, role, company, backgroundImage, authorImage, wpCaseStudy } = props
  console.log(wpCaseStudy);
  const slug = wpCaseStudy?.slug;
  const GlobalStyle = createGlobalStyle`
    .items-center .space-x-2 > a {
    color: #FFF !important;
    font-family: Favorit Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
    
    .items-center .space-x-2 >  svg > path{
fill: #FFF !important;
}
.btn{
border-radius:12px;
}
    svg.w-full.h-auto >path {
    fill: #FFF;
}
    .small-text{
    font-size: 19px !important;
    }
 .video-thumb-img {
   background-color: rgba(250, 247, 244, 1);
   width: 70% !important;
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom:4rem;
} 
    .bullet-points.casestudy-bullets > p {
    font-family: 'Favorit Pro';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
     .bullet-points.casestudy-bullets.about-company > p{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-family: Favorit Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
} 
    .bullet-points.casestudy-bullets > p > a {
   color: #E4572E;
   font-weight:600;
   text-decoration:underline;
    } 
   span.casestudy-bullets{
   color:#000;
   }
       
`

  const stats = [
    {
      title: wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.heroStateHeading,
      value: wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.heroFirstStateDesc,
    },
    {
      title: wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.heroSecStateHeading,
      value: wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.heroSecStateDesc,
    },
    {
      title: wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.heroThirdStateHeading,
      value: wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.heroThirdStateDesc,
    },
  ];



  const videoRef = useRef(null);

  const scrollToVideo = (event) => {
    event.preventDefault(); // Prevent default <a> click behavior
    if (videoRef.current) {
      videoRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className="w-full min-h-screen">
      <GlobalStyle></GlobalStyle>
      <header className="relative h-[80vh] w-full newheader-header">

        <div className="absolute inset-0">

          <img
            src={backgroundImage.localFile.publicURL}
            alt="Professional in modern office setting"
            className="w-full h-full object-cover object-center"
          />
          <div className="customer-hero__black-gradient"></div>
          {slug === 'pathway-homes' ? (
  <div className="absolute inset-0 bg-[#0a4a619c]">
<div className="absolute bottom-0 inset-x-0 h-[10rem] backdrop-blur-[24px] shadow-[0px_0px_20px_0px_#00000029] bg-[linear-gradient(0deg,rgba(98,94,94,0.61)_0%,rgba(84,84,84,0.41)_100%)]"></div>

  </div>
) : (
  <div className="absolute inset-0"> 
    <div className="absolute bottom-0 inset-x-0 h-[8rem] backdrop-blur-[8px] shadow-[0px_0px_20px_0px_#00000029] bg-[linear-gradient(0deg,rgba(0,126,172,0.4)_0%,rgba(0,126,172,0.2)_100%)]"></div>
  </div>
)}

          


        </div>

        <div className="relative z-10 max-w-6xl mx-auto logopadding px-4 pt-24">

          <div className="flex gap-2 items-center mb-4">
            <div className="flex items-center space-x-2">
              <img
                src={logo.localFile.publicURL}
                alt="Classpass logo"
                className="h-8 classpasslogo"
              />
            </div>
          </div>


          <h1 className="text-4xl md:text-6xl font-bold text-white max-w-3xl mb-16 headerheading">
            {subheading}
          </h1>
          <a href="#" onClick={scrollToVideo} className="flex items-center gap-2 mb-16">
            <button className="px-[16px] py-[6px] bg-[#EE6B4C] text-white rounded-[12px] hover:bg-orange-600 transition-colors font-medium flex items-center gap-2 border-none text-left text-[16px] font-[500] leading-[32px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.25l13.5 6.75-13.5 6.75V5.25z" />
              </svg>
              <span>Watch the video</span>
            </button>
          </a>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 py-6">
            {stats.map((stat, index) => (
              <div
                key={index}
                className={`p-3 ${index !== stats.length - 1 ? "md:border-r border-white/20" : ""}`}
              >
                <h3 className="text-white/80 text-sm mb-2 statstitle">{stat.title}</h3>
                <p className="mb-2 statsval">{stat.value}</p>
              </div>
            ))}
          </div>
        </div>
      </header>
      <div ref={videoRef} className="video-thumb-img relative w-full pb-[40.25%] ">
        <iframe
          src={wpCaseStudy?.postTypeCaseStudy?.loomVideoLink}
          frameBorder="0"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full rounded-[12px]"
        ></iframe>

      </div>


      <div className="bg-[#F3EFEB]">
        <section className="max-w-6xl mx-auto px-4">
          {/* First Grid Section - 8:4 ratio */}
          <div className="py-16 grid grid-cols-1 md:grid-cols-[8fr_4fr] gap-1 md:gap-1 items-center">
            {/* Left Column (8 parts) */}
            <div className="md:pr-9 bg-[rgba(240,237,234,1)] border border-[rgba(219,219,219,1)] p-[200px_60px_65px_60px] rounded-[12px]">
              <div className='author-quote'>

                <p className="text-2xl text-gray-700 mb-6 leading-relaxed d-none" dangerouslySetInnerHTML={{ __html: quote }}></p>
                <img src="/quote-icon.svg" alt="Twitter" className="w-6 h-6" />
                <p className="text-2xl text-gray-700 mb-6 leading-relaxed" dangerouslySetInnerHTML={{ __html: quote }}></p>
                <div className="flex items-center gap-4">
                  <img
                    src={authorImage.localFile.publicURL}
                    alt="Quote author"
                    className="w-[3rem] h-[3rem] rounded-full object-cover"
                  />
                  <p className="text-sm text-gray-600 small-text">
                    {`${name ? `With ${name},` : ``} ${role ? `${role}` : ''} ${company ? `at ${company}` : ``}`}
                  </p>
                </div>
              </div>
              <h2 className="text-[14px] font-[400] leading-[20px] tracking-[1%] font-[Favorit Pro] smb-8 mt-14 form-description text-[rgba(102, 102, 102, 1)]">
                Check out the latest new features and product updates from the Setpoint team.
                <a href="https://new.setpoint.io/"><span className="cursor-pointer text-[rgb(228,87,46)] hover:text-[rgba(228,87,46,0.8)] transition-colors">
                  &nbsp; Click here →
                </span>
                </a>
              </h2>

            </div>

            {/* Right Column (4 parts) - Social Links */}
            <div className="md:pl-8 flex gap-4 md:justify-end">
              <ul className="grid grid-cols-2 lg:block about-sidebar">
                {/* Social Media Icons Section */}
                <li className="mb-8 col-span-2">
                  <div className="flex gap-4 items-center">
                    <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookLink} target="_blank" rel="noopener noreferrer">
                      <img src="/icon-facebook.png" alt="Facebook" className="w-6 h-6 opacity-70" />
                    </a>
                    <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterLink} target="_blank" rel="noopener noreferrer">
                      <img src="/icon-twitter.png" alt="Twitter" className="w-6 h-6 opacity-70" />
                    </a>
                    <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinLink} target="_blank" rel="noopener noreferrer">
                      <img src="/icon-linkedin.png" alt="LinkedIn" className="w-6 h-6 opacity-70" />
                    </a>
                    {/* <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookImage.localFile.publicURL} alt="Facebook" className="w-6 h-6" />
          </a>
          <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterImage.localFile.publicURL} alt="Twitter" className="w-6 h-6" />
          </a>
          <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinImage.localFile.publicURL} alt="LinkedIn" className="w-6 h-6" />
          </a> */}
                  </div>
                </li>
                <li className="mb-4 col-span-2">
                  <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.logoWhite.localFile.publicURL} alt="Facebook" className="company-logo" />
                </li>
                {/* Existing Company Menu */}
                <li className="mb-4 col-span-2">
                  <h3 className="uppercase t-12-bold mb-1 company-label">Company</h3>
                  <span className='casestudy-bullets'>{wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.company}</span><br></br>
                  <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.companyLink}> <span className='text-[rgb(228,87,46)] hover:text-[rgba(228,87,46,0.8)]'>
                    {wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.companyLink?.replace(/(^\w+:|^)\/\//, '')}
                  </span></a>
                </li>
                <li className="mb-4 col-span-2">
                  <h3 className="uppercase t-12-bold mb-1 company-label">Industry</h3>
                  <span className='casestudy-bullets'>{wpCaseStudy?.postTypeCaseStudy?.industry}</span>
                </li>
                <li className="mb-4 col-span-2">
                  <h3 className="uppercase t-12-bold mb-1 company-label">Company size</h3>
                  <span className='casestudy-bullets'>{wpCaseStudy?.postTypeCaseStudy?.companySize}</span>
                </li>
                <li className="mb-4 col-span-2 ">
                  <h3 className="uppercase t-12-bold mb-1 company-label">Key pain points</h3>
                  <div
                    className="bullet-points casestudy-bullets"
                    dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.keyPoints }}
                  />
                </li>
                {wpCaseStudy?.postTypeCaseStudy?.rampProductsUsed && (
                  <li className="mb-4 col-span-2">
                    {slug === 'pathway-homes' ? (
                    <h3 className="uppercase t-12-bold mb-1 company-label">Setpoint Products Used</h3>
                  ) : (
                    <h3 className="uppercase t-12-bold mb-1 company-label">Ramp products used</h3>
                  )}
                  
                    <div
                      className="bullet-points casestudy-bullets"
                      dangerouslySetInnerHTML={{
                        __html: wpCaseStudy?.postTypeCaseStudy?.rampProductsUsed,
                      }}
                    />
                  </li>
                )}
                {wpCaseStudy?.postTypeCaseStudy?.aboutTheCompany && (
                  <li className="mb-4 col-span-2">
                    <h3 className="uppercase t-12-bold mb-1 company-label">About the company</h3>
                    <div
                      className="bullet-points about-company casestudy-bullets"
                      dangerouslySetInnerHTML={{
                        __html: wpCaseStudy?.postTypeCaseStudy?.aboutTheCompany,
                      }}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>


  )
}


