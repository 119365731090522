import { graphql } from "gatsby";
import * as React from "react";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from "~/components/Layout";
import { ArticleRenderer } from '../components/ArticleRenderer';
import { CaseStudyMasthead } from '../components/flexible/mastheads/CaseStudyMasthead';
import { CaseStudyNewHead } from '../components/flexible/mastheads/CaseStudyNewHead'; // New Component
import { Button } from '../components/elements/buttons/Button';
import Image from '~/components/elements/Image';
import { Link } from "gatsby";
import { createGlobalStyle } from 'styled-components';
import { useResources } from '~/hooks/useResources';
import DateFormat from '~/components/elements/date';

export default function CaseStudyPage({
  data: { wpCaseStudy, wp },
}) {
  const slug = wpCaseStudy?.slug; // Slug from GraphQL
  const posts = useResources();

const blog = posts
  ?.filter((p) => p?.categories?.nodes.some((c) => c.name === 'Blog'))
  .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()); // Sort by latest date

  const recentBlogs = blog?.slice(0, 3); // Show only the latest 3 posts


   console.log('posts: ', posts);
  console.log('Case Study Slug:', wpCaseStudy);
  const Headingstyle = {
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '30px',
    marginTop: '30px',
    fontWeight: 700, // numeric value, no quotes needed
    color: '#999999',
  };
  const GlobalStyle = createGlobalStyle`
  p {
    font-size: 18px;
    margin-top: 25px !important;
    margin-bottom: 25px !important; 
    color: #000 !important;
  }

  h2 {
      font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 700, // numeric value, no quotes needed,
    color: '#000',
  }
  h3 {
    font-size: 36px;
    margin-bottom:40px;
    line-height: 40px;
}
    .classpasslogo{
    width: 100%;
    max-width: 387px;
    height: auto;
    max-height: 154px;
}
    h1.headerheading {
 font-family: Gascogne Serial;
font-size: 56px;
font-weight: 400;
line-height: 64px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
h3.statstitle {
    font-family: Favorit Pro;
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: #FFF !important;
  
}
   p.statsval {
   font-family: Favorit Pro;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #A8B7B8 !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    }
    img.author-image {
    width: 20%;
    height: auto;
    aspect-ratio: 1;
    filter: grayscale();
    max-height: 100%;
}
    .yellowbtn {
        color: #1c1b17e6;
        background-color: #e4f222;
        transition: background-color .2s ease-in;
        position: absolute;
        margin-right: 0%;
        padding: 12px !important;
        margin-top: 0px;
}
        .emailfield{
            border: unset;
    height: 60px;
        }
    .border-color{
    border-color: #1c1b171a !important;
    }
    .author-quote > p, .author-quote > p > p {
    font-family: Gascogne Serial;
font-size: 32px;
font-weight: 400;
line-height: 48px;
text-align: left;    
color: #4C4C4C !important;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}   
   .d-none{
   visibility:hidden;
   display:none;
   }
   .logopadding{
   padding-top:8rem !important;
   }
   .newheader-header {
    height: auto !important;
}
    .small-text{
    font-family: 'Favorit Pro';
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
    .about-sidebar{
        background: linear-gradient(180deg, #FFFFFF 0%, #FAF7F4 100%);
    box-shadow: 0px 1px 4px 0px #00000029;
    padding: 60px;
    border-radius: 12px;
    }
    .case-study-details {
    width: 65% !important;
    margin: 0 auto;
    background-color: transparent;
}
    .company-label{
    font-family: Favorit Pro;
        font-size: 10px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #999999;}
        .form-description{
        font-family: Favorit Pro;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(102, 102, 102, 1);
        }
    blockquote {
    border-left-color: #2c9c88 !important;
    border-left: 3px solid;
    padding-left: 1em;
    font-family: Gascogne Serial;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
}
    .post-heading{
         font-family: Gascogne Serial;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: #000 !important;
    }
    span.text-coral.readmore {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    color: #354648;
    text-decoration: underline;
        text-decoration-color: #EE6B4C;
    text-decoration-thickness: 2px;
}
    h2.resource-heading {
    font-family: 'Gascogne Serial';
    font-size: 58px;
    font-weight: 400;
    line-height: 58px;
    text-align: left;
    color: #071B1E;
}
    .casestudy-text > p {
    font-family: Favorit Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #071B1EB2 !important;
}
    li{
    color: #071B1EB2 !important;
    }
    li > strong{
    color: #333 !important;
    }
    blockquote > p{
        font-family: Gascogne Serial !important;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    margin-top:50px !important;
    margin-bottom: 50px !important;
    }
    p.casestudy-text {
    margin-bottom: 7rem !important;
}
    .bullet-pointsss{
  padding: 50px 70px 50px 70px;
    list-style: disc;
    border: 1px solid #FBFAF9;
    background-color: #fff !important;
    box-shadow: 0px 1px 4px 0px #00000029;
    border-radius: 20px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    }
    .casestudy-text.casestudy-sol {
    margin-bottom: 5rem !important;
}
    `;
  return (

    <Layout wp={wp}>
      <Seo post={wpCaseStudy} />
      <GlobalStyle />
      {/* Conditional Rendering Based on Slug */}
      {slug === 'casestudytestpage' || slug === 'pathway-homes' ? (
        <CaseStudyNewHead
          {...wpCaseStudy?.postTypeCaseStudy?.caseStudySummary}
          wpCaseStudy={wpCaseStudy}  // Add this line
        />
      ) : (

        <CaseStudyMasthead {...wpCaseStudy?.postTypeCaseStudy?.caseStudySummary} />
      )}

      <section className="section-contained xl:!w-[1360px] case-study-details font-body section-top section-bot bg-white rounded-3xl p-6 lg:py-26 lg:px-20">

        <div className="flex flex-col lg:flex-row gap-y-10 gap-x-8 lg:gap-36 relative">

          {/* Conditional Rendering Based on Slug */}
          {slug === 'casestudytestpage' || slug === 'pathway-homes' ? (

            <article className="flex-auto casestudy-article">

              <div className="prblem-sec">
                <h2 className="d-none" style={Headingstyle}>{wpCaseStudy?.postTypeCaseStudy?.theProblemHeading}</h2>
                <p className="d-none" dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.theProblemContent }} />

                <h2 style={Headingstyle}>{wpCaseStudy?.postTypeCaseStudy?.theProblemHeading}</h2>
                <p className="mb-4 casestudy-text casestudy-sol" dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.theProblemContent }} />
                <img
                  src={wpCaseStudy?.postTypeCaseStudy?.theProblemImage.localFile.publicURL}
                  alt="Professional in modern office setting"
                  className="w-full h-full object-cover object-center mb-16"
                />
                <h2 style={Headingstyle}>{wpCaseStudy?.postTypeCaseStudy?.theSolutionHeading}</h2>
                <p className="mb-4 casestudy-text" dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.theSolutionContent }} />

                <h2 style={Headingstyle}>{wpCaseStudy?.postTypeCaseStudy?.theResultHeading}</h2>
                <p className="mb-4 casestudy-text" dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.theResultContent }} />

              </div>
            </article>

          ) : (
            <article className="flex-auto">
              <ArticleRenderer
                prefix="CaseStudy_Posttypecasestudy_ArticleContent_"
                content={wpCaseStudy?.postTypeCaseStudy?.articleContent}
              />
            </article>
          )}

{slug !== 'casestudytestpage' && slug !== 'pathway-homes' && (
            <div className="lg:max-w-[260px]">
              <div className="top-[4.5rem]">
                <ul className="grid grid-cols-2 lg:block">
                  {/* Social Media Icons Section */}
                  <li className="mb-8 col-span-2">
                    <div className="flex gap-4 items-center">
                      <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookLink} target="_blank" rel="noopener noreferrer">
                        <img src="/fb.svg" alt="Facebook" className="w-6 h-6" />
                      </a>
                      <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterLink} target="_blank" rel="noopener noreferrer">
                        <img src="/twitter.svg" alt="Twitter" className="w-6 h-6" />
                      </a>
                      <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinLink} target="_blank" rel="noopener noreferrer">
                        <img src="/linkedin.svg" alt="LinkedIn" className="w-6 h-6" />
                      </a>
                      {/* <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.facebookImage.localFile.publicURL} alt="Facebook" className="w-6 h-6" />
          </a>
          <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.twitterImage.localFile.publicURL} alt="Twitter" className="w-6 h-6" />
          </a>
          <a href={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinLink} target="_blank" rel="noopener noreferrer">
            <img src={wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.linkedinImage.localFile.publicURL} alt="LinkedIn" className="w-6 h-6" />
          </a> */}
                    </div>
                  </li>

                  {/* Existing Company Menu */}
                  <li className="mb-8">
                    <h3 className="uppercase t-12-bold mb-1">Company</h3>
                    <span>{wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.company}</span>
                  </li>
                  <li className="mb-8">
                    <h3 className="uppercase t-12-bold mb-1">Industry</h3>
                    <span>{wpCaseStudy?.postTypeCaseStudy?.industry}</span>
                  </li>
                  <li className="mb-8">
                    <h3 className="uppercase t-12-bold mb-1">Company size</h3>
                    <span>{wpCaseStudy?.postTypeCaseStudy?.companySize}</span>
                  </li>
                  <li className="mb-8 col-span-2">
                    <h3 className="uppercase t-12-bold mb-1">Key pain points</h3>
                    <div
                      className="bullet-points casestudy-bullets"
                      dangerouslySetInnerHTML={{ __html: wpCaseStudy?.postTypeCaseStudy?.keyPoints }}
                    />
                  </li>
                  {wpCaseStudy?.postTypeCaseStudy?.rampProductsUsed && (
                    <li className="mb-8 col-span-2">
                      <h3 className="uppercase t-12-bold mb-1">Ramp products used</h3>
                      <div
                        className="bullet-points casestudy-bullets"
                        dangerouslySetInnerHTML={{
                          __html: wpCaseStudy?.postTypeCaseStudy?.rampProductsUsed,
                        }}
                      />
                    </li>
                  )}
                  {wpCaseStudy?.postTypeCaseStudy?.aboutTheCompany && (
                    <li className="mb-8 col-span-2">
                      <h3 className="uppercase t-12-bold mb-1">About the company</h3>
                      <div
                        className="bullet-points casestudy-bullets"
                        dangerouslySetInnerHTML={{
                          __html: wpCaseStudy?.postTypeCaseStudy?.aboutTheCompany,
                        }}
                      />
                    </li>
                  )}
                </ul>
                <Button
                  type="button"
                  color="coral"
                  link={wpCaseStudy?.postTypeCaseStudy?.ctaLink}
                />
              </div>
            </div>
          )}
        </div>
      </section>
      {slug === "casestudytestpage" || slug === 'pathway-homes' ? (
        <div className="container !max-w-[1200px] grid grid-cols-1">
          <div className="flex items-center justify-between">
            <h2 className="resource-heading">More resources from Setpoint</h2>
            <a href="/resources#Blog" className="flex items-center gap-2 font-[Favorit Pro] text-[16px] font-medium leading-[24px] tracking-[-0.01em] text-center underline decoration-transparent hover:decoration-inherit">
              View All
              <span className="w-[24px] h-[24px] flex items-center justify-center rounded-full bg-orange text-white">
                ➜
              </span>
            </a>
          </div>
          <ul className="container !max-w-[1200px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-12 pb-7">
  {recentBlogs?.map((post, i) => (
    <RecommendedPostNewCaseStudy key={i} post={post} />
  ))}
</ul>
          <div className="cta mt-[4rem] mb-[4rem]">
            <a href="/speak-with-us/">
              <img src="/cta.png" className="cta-img"></img>
            </a>
          </div>
        </div>

      ) : (
        <ul className="container !max-w-[1200px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-12 pb-7">
          {wpCaseStudy?.postTypeCaseStudy?.recommended?.map((post, i) => (
            <RecommendedPost key={i} post={post.recommendedItem} />
          ))}
        </ul>
      )}
    </Layout>
  );
}

const RecommendedPost = ({ post }) => {
  console.log(post);
  return (
    <li className="bg-white rounded-[2rem] border-[1px] border-black/10">
      <Link to={post?.uri} className="block p-6 md:p-8 lg:p-[40px] hover:opacity-50 transition-all duration-200">
        <div className="mb-10">
          {post?.featuredImage && (
            <Image
              className="aspect-[302/214] rounded-[20px]"
              objectFit="cover"
              objectPosition="center"
              image={post?.featuredImage?.node}
            />
          )}
          {!post?.featuredImage && <div className="aspect-[302/214] rounded-[20px] bg-paleBlue" />}
        </div>
        <div className="max-w-[75px] h-[1.5rem] mb-6">
          <Image
            className="w-full h-full object-contain object-center"
            image={post?.postTypeCaseStudy?.caseStudySummary?.logo}
          />
        </div>
        <h3 className="t-heading-30 mb-8">{post?.title}</h3>
        <span className="text-coral t-19">Read story</span>
      </Link>
    </li>
  );
};

const RecommendedPostNewCaseStudy = ({ type = 'large', post }) => {
  console.log('recent blog',post);
  const image = post?.postTypePost?.summary?.image;
  return (
    <li className="group block">
      <Link to={post?.uri} className={`group block ${type === 'small' ? 'md:flex md:space-x-5' : ''}`}>
      <div
        className={`${
          type === 'small' ? 'w-full md:flex-[0_0_33%] md:aspect-square' : 'w-full'
        } aspect-[486/360] rounded-[10px] overflow-hidden bg-grey`}
      >
        <Image
          objectFit={'cover'}
          image={image}
          className="w-full h-full object-cover group-hover:opacity-80 duration-200 transition-opacity"
        />
      </div>
      <div className={`pt-5 ${type !== 'small' ? '' : 'md:py-2'} space-y-3 flex flex-col items-start`}>
        <div className={`mr-4 flex-auto`}>
          <div
            className={`text-dark t-22 ${type === 'small' ? '' : 'md:t-24'} leading-snug line-clamp-3`}
            dangerouslySetInnerHTML={{ __html: post?.title }}
          />
          <div className="t-14 text-dark text-opacity-60 mt-1.5">
            <DateFormat dateString={post.date} />
          </div>
        </div>
        <div className="text-dark font-bold relative inline-block pt-1 hover:opacity-90 transition-opacity duration-200">
          Read more
          <div className="absolute inset-x-0 bottom-[-2px] h-[2px] w-full bg-orange"></div>
        </div>
      </div>
    </Link>
    </li>
  );
};


export const pageQuery = graphql`
  query CaseStudy($id: String!) {
    wpCaseStudy(id: { eq: $id }) {
      ...SEOCaseStudy
      ...GeneratedWpCaseStudy
      slug # Fetching the slug
    }
    wp {
      ...GeneratedWp
      header {
        optionsHeader {
          menu {
            link {
              url
              title
            }
            showSubmenu
            submenu {
              type
              withPostMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                    description
                  }
                }
              }
              withImageMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                rightColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                image {
                  ...Image
                }
              }
            }
          }
        }
      }
    }
  }
`;
